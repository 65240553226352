import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Center,
  SimpleGrid,
  Image,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Spacer,
  Badge,
  Select,
  useToast,
  Text,
} from '@chakra-ui/react';
import useAppDispatch from '../redux/hooks/useAppDispatch';
import useAppSelector from '../redux/hooks/useAppSelector';
import * as campaignThunk from '../redux/thunks/campaign';
import { AsyncState } from '../types/state.enum';
import { format } from 'date-fns';
import Waypoint from '../components/Waypoint';

export default function Campaign() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('-created_at');
  const toast = useToast();
  const dispatch = useAppDispatch();
  const campaignListData = useAppSelector(
    (state) => state.campaign.campaignList.data
  );
  const hasMoreCampaignListData = useAppSelector(
    (state) => state.campaign.campaignList.has_more
  );
  const isGetCampaignListPending = useAppSelector(
    (state) => state.campaign.getCampaignStatus === AsyncState.PENDING
  );

  const getCampaign = useCallback(
    async (limit: number, page: number, sort: string) => {
      try {
        await dispatch(
          campaignThunk.getCampaign({ limit, page, sort })
        ).unwrap();
      } catch (e: any) {
        toast({
          title: `${e.code} ${e.msg}`,
          status: 'error',
          position: 'top',
        });
      }
    },
    [dispatch, toast]
  );

  useEffect(() => {
    getCampaign(10, page, sort);
  }, [getCampaign, page, sort]);

  return (
    <Container maxW={'2560px'} pt={'20px'}>
      <Box py={'10px'}>
        <Text mb={'10px'}>Campaign</Text>
        <Select
          size={'sm'}
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <option value={'-created_at'}>Created at</option>
        </Select>
      </Box>
      <SimpleGrid
        columns={[1, 2, 3, 4, 5, 6]}
        spacing={'10px'}
        w={'full'}
        h={'calc(100dvh - 140px)'}
        pb={'20px'}
        overflow={'scroll'}
      >
        {campaignListData.map((campaign) => (
          <Card
            onClick={() => navigate(`/campaign/${campaign.id}`)}
            key={campaign.id}
            variant={'outline'}
          >
            <CardBody p={2}>
              <Center w={'100%'} h={'100%'}>
                <Image
                  borderRadius={'5px'}
                  src={campaign.token_uri}
                  minH={200}
                  minW={200}
                  maxH={200}
                  maxW={200}
                  objectFit={'scale-down'}
                />
              </Center>
            </CardBody>
            <CardFooter display={'block'}>
              <HStack w={'full'} mb={'5px'}>
                <Box fontWeight={'semibold'} fontSize={'sm'}>
                  {campaign.campaign_info.title}
                </Box>
                <Spacer />
                <Badge
                  bgColor={'#ffffff'}
                  border={'0.1px solid black'}
                  textTransform={'capitalize'}
                  fontWeight={'light'}
                >
                  {campaign.type}
                </Badge>
              </HStack>
              <HStack w={'full'}>
                <Box fontWeight={'light'} fontSize={'xs'}>
                  <HStack wrap={'wrap'}>
                    <Box w={'30px'}>Start: </Box>
                    <Box>
                      {format(
                        new Date(campaign.claim_period.start),
                        'yyyy-MM-dd'
                      )}
                    </Box>
                  </HStack>
                  <HStack wrap={'wrap'}>
                    <Box w={'30px'}>End: </Box>
                    <Box>
                      {format(
                        new Date(campaign.claim_period.end),
                        'yyyy-MM-dd'
                      )}
                    </Box>
                  </HStack>
                </Box>
                <Spacer />
                <Badge
                  bgColor={'#ffffff'}
                  border={'0.1px solid black'}
                  textTransform={'capitalize'}
                  textAlign={'center'}
                  fontWeight={'light'}
                >
                  <Box>Max Redeem</Box>
                  <Box>{campaign.usage_limit}</Box>
                </Badge>
              </HStack>
            </CardFooter>
          </Card>
        ))}
        <Waypoint
          onEnter={() => setPage((prevState) => prevState + 1)}
          disabled={!hasMoreCampaignListData || isGetCampaignListPending}
        />
      </SimpleGrid>
    </Container>
  );
}

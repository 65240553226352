import scanService from './services/scan';

export const getCampaign = async (
  params: Param.CampaignPagination
): Promise<Entity.CampaignList> => {
  const { data } = await scanService.get<Entity.CampaignList>('/v1/campaigns', {
    params,
  });

  return data;
};

export const getCampaignById = async (id: string): Promise<Entity.Campaign> => {
  const { data } = await scanService.get<Entity.Campaign>(
    `/v1/campaigns/${id}`
  );

  return data;
};

import { useEffect, useState, useCallback, Fragment } from 'react';
import {
  useToast,
  Container,
  List,
  ListItem,
  Text,
  Divider,
  HStack,
  Box,
  Select,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { Waypoint } from 'react-waypoint';
import { format } from 'date-fns';
import useAppDispatch from '../redux/hooks/useAppDispatch';
import useAppSelector from '../redux/hooks/useAppSelector';
import * as redemptionThunk from '../redux/thunks/redemption';
import { AsyncState } from '../types/state.enum';

export default function RedeemHistory() {
  const dispatch = useAppDispatch();
  const redemptionList = useAppSelector(
    (state) => state.redemption.redemptionList
  );
  const isGetRedemptionListPending = useAppSelector(
    (state) => state.redemption.getRedemptionsStatus === AsyncState.PENDING
  );
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('-created_at');
  const toast = useToast();

  const getRedemptions = useCallback(
    async (limit: number, page: number, sort: string) => {
      try {
        await dispatch(
          redemptionThunk.getRedemptions({ limit, page, sort })
        ).unwrap();
      } catch (e: any) {
        toast({
          title: `${e.code} ${e.msg}`,
          status: 'error',
          position: 'top',
        });
      }
    },
    [dispatch, toast]
  );

  useEffect(() => {
    getRedemptions(10, page, sort);
  }, [getRedemptions, page, sort]);

  return (
    <Container maxW={'2560px'} p={'20px 0px 0px 0px'}>
      <Box p={'10px'}>
        <Text mb={'10px'}>Redemption History</Text>
        <Select
          size={'sm'}
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <option value={'-created_at'}>Created at</option>
        </Select>
      </Box>

      <List
        borderTop={'0.1px solid lightgrey'}
        borderBottom={'0.1px solid lightgrey'}
        h={'calc(100vh - 250px)'}
        overflow={'scroll'}
      >
        {redemptionList.data.map(
          ({
            id,
            campaign_name,
            token_name,
            user_email,
            current_usage,
            redeem_time,
          }) => {
            return (
              <Fragment key={id}>
                <ListItem p={'10px 20px 10px 20px'}>
                  <HStack
                    w={'full'}
                    justifyContent={'space-between'}
                    alignItems={'start'}
                  >
                    <Box fontSize={'sm'}>
                      <Box fontWeight={'semibold'}>Campaign Name</Box>
                      <Box fontWeight={'light'} mb={'5px'}>
                        {campaign_name}
                      </Box>
                      <Box fontWeight={'semibold'}>NFT Token</Box>
                      <Box fontWeight={'light'} mb={'5px'}>
                        {token_name}
                      </Box>
                      <Box fontWeight={'semibold'}>Redeemed by</Box>
                      <Box fontWeight={'light'} mb={'5px'}>
                        {user_email}
                      </Box>
                      <Box fontSize={'xs'} fontWeight={'light'}>
                        {format(new Date(redeem_time), 'yyyy-MM-dd hh:mm:ss')}
                      </Box>
                    </Box>
                    <Box textAlign={'center'} fontSize={'xs'}>
                      <Box fontSize={'xs'}>Usage</Box>
                      <Box fontWeight={'light'}>{current_usage} time(s)</Box>
                    </Box>
                  </HStack>
                </ListItem>
                <Divider />
              </Fragment>
            );
          }
        )}
        {isGetRedemptionListPending && (
          <Center p={'20px'}>
            <Spinner color={'coap.blue.5'} />
          </Center>
        )}
        {redemptionList.has_more && !isGetRedemptionListPending && (
          <Waypoint onEnter={() => setPage((prevState) => prevState + 1)} />
        )}
      </List>
    </Container>
  );
}

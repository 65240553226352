import { configureStore } from '@reduxjs/toolkit';
import config from '../config';
import accountReducer from './reducers/account';
import campaignReducer from './reducers/campaign';
import redemptionReducer from './reducers/redemption';

export const store = configureStore({
  devTools: config.env === 'development',
  reducer: {
    account: accountReducer,
    campaign: campaignReducer,
    redemption: redemptionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

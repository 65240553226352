import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Heading,
  Center,
  Divider,
  Text,
  Button,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import useAppDispatch from '../redux/hooks/useAppDispatch';
import useAppSelector from '../redux/hooks/useAppSelector';
import * as redemptionAction from '../redux/actions/redemption';

export default function RedeemSuccess() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redeemResult = useAppSelector((state) => state.redemption.redeemResult);

  useEffect(() => {
    return () => {
      dispatch(redemptionAction.resetRedeemResult());
    };
  }, [dispatch]);

  if (!redeemResult) {
    return <Navigate to={'/campaign'} replace />;
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <Box p={'50px 10px 10px 10px'}>
        <Heading
          size={'lg'}
          textAlign={'center'}
          color={'coap.blue.1'}
          fontWeight={'extrabold'}
          mb={'20px'}
        >
          SUCCESS
        </Heading>
        <Center>
          <Box
            w={'100%'}
            border={'0.1px solid lightgrey'}
            borderRadius={'10px'}
            textAlign={'center'}
            px={'20px'}
            mb={'20px'}
          >
            <Box py={'10px'}>
              <Text fontWeight={'semibold'} fontSize={'md'}>
                Campaign Name
              </Text>
              <Text fontWeight={'light'} fontSize={'md'}>
                {redeemResult.campaign_name}
              </Text>
            </Box>
            <Divider />
            <Box py={'10px'}>
              <Text fontWeight={'semibold'} fontSize={'md'}>
                COAP NFT Name
              </Text>
              <Text fontWeight={'light'} fontSize={'md'}>
                {redeemResult.token_name}
              </Text>
            </Box>
            <Divider />
            <Box py={'10px'}>
              <Text fontWeight={'semibold'} fontSize={'md'}>
                User Email
              </Text>
              <Text fontWeight={'light'} fontSize={'md'}>
                {redeemResult.user_email}
              </Text>
            </Box>
            <Divider />
            <Box py={'10px'}>
              <Text fontWeight={'semibold'} fontSize={'md'}>
                Use times
              </Text>
              <Text fontWeight={'light'} fontSize={'md'}>
                {redeemResult.current_usage}
              </Text>
            </Box>
            <Divider />
            <Box py={'10px'}>
              <Text fontWeight={'semibold'} fontSize={'md'}>
                Accepted at
              </Text>
              <Text fontWeight={'light'} fontSize={'md'}>
                {format(
                  new Date(redeemResult.redeem_time),
                  'yyyy-MM-dd hh:mm:ss'
                )}
              </Text>
            </Box>
          </Box>
        </Center>
        <Button
          w={'full'}
          borderRadius={'50px'}
          bgColor={'coap.yellow.2'}
          _active={{
            bgColor: 'coap.yellow.2',
          }}
          _hover={{
            bgColor: 'coap.yellow.2',
          }}
          onClick={() => navigate('/campaign', { replace: true })}
        >
          Home
        </Button>
      </Box>
    </Container>
  );
}

import { useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  Box,
  HStack,
  Spacer,
  Image,
  Center,
  Text,
  Button,
  VStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AspectRatio,
  Skeleton,
  useToast,
} from '@chakra-ui/react';
import { isAfter, isBefore } from 'date-fns';
import useAppDispatch from '../redux/hooks/useAppDispatch';
import useAppSelector from '../redux/hooks/useAppSelector';
import * as campaignThunk from '../redux/thunks/campaign';
import * as redemptionThunk from '../redux/thunks/redemption';
import { AsyncState } from '../types/state.enum';
import { CampaignType } from '../types/campaign.enum';
import QRCodeScanner from '../components/QRCodeScanner';

export default function CampaignById() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const campaign = useAppSelector((state) => state.campaign.campaign);
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onClose: onAlertClose,
    onOpen: onAlertOpen,
  } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const isGetCampaignByIdSuccess = useAppSelector(
    (state) => state.campaign.getCampaignByIdStatus === AsyncState.SUCCESS
  );
  const isGetCampaignByIdPending = useAppSelector(
    (state) => state.campaign.getCampaignByIdStatus === AsyncState.PENDING
  );

  const getCampaignById = useCallback(
    async (campaignId: string) => {
      try {
        await dispatch(campaignThunk.getCampaignById(campaignId)).unwrap();
      } catch (e: any) {
        toast({
          title: `${e.code} ${e.msg}`,
          status: 'error',
          position: 'top',
        });

        navigate('/campaign', { replace: true });
      }
    },
    [dispatch, navigate, toast]
  );

  const handleScanAndRedirect = useCallback(
    async (token?: string) => {
      try {
        if (!token) return;
        const redeemInfo = await dispatch(
          redemptionThunk.fetchInfo({
            token,
          })
        ).unwrap();

        const navigateWithErrorMsg = (msg: string, code: string) => {
          const payload = {
            msg,
            code,
          };

          navigate(`/redeem/fail?r=${window.btoa(JSON.stringify(payload))}`);
        };

        const currentTime = new Date();
        const campaignStartDate = new Date(
          redeemInfo.campaign.valid_period.start
        );

        if (isBefore(currentTime, campaignStartDate)) {
          navigateWithErrorMsg('The campaign has not yet started', '');
          return;
        }

        const campaignEndDate = new Date(redeemInfo.campaign.valid_period.end);

        if (isAfter(currentTime, campaignEndDate)) {
          navigateWithErrorMsg('The campaign has been ended', '');
          return;
        }

        if (redeemInfo.campaign.type === CampaignType.VOUCHER) {
          const claimStartDate = new Date(
            redeemInfo.campaign.valid_period.start
          );

          if (isBefore(currentTime, claimStartDate)) {
            navigateWithErrorMsg('The voucher has not yet activated', '');
            return;
          }
          const claimEndDate = new Date(redeemInfo.campaign.valid_period.end);

          if (isAfter(currentTime, claimEndDate)) {
            navigateWithErrorMsg('The voucher has been expired', '');
            return;
          }
          if (redeemInfo.current_usage > redeemInfo.campaign.usage_limit) {
            navigateWithErrorMsg('Voucher usage limit exceed', '');
            return;
          }
        }

        if (redeemInfo.campaign.id !== campaign?.id) {
          navigateWithErrorMsg(
            'The campaign/token you selected does not match with the QR Code you are scanning.',
            ''
          );
          return;
        }

        navigate('/redeem/confirm');
      } catch (e: any) {
        navigate(`/redeem/fail?r=${window.btoa(JSON.stringify(e))}`);
      }
    },
    [campaign?.id, dispatch, navigate]
  );

  useEffect(() => {
    if (searchParams.get('scan')) {
      onOpen();
      setSearchParams({});
    }
  }, [onOpen, searchParams, setSearchParams]);

  useEffect(() => {
    getCampaignById(id as string);
  }, [getCampaignById, id]);

  return (
    <Container maxW={'2560px'} p={0}>
      <Box p={'70px 10px 10px 10px'} h={'calc(100dvh - 50px)'} pb={'20px'}>
        <Center>
          <Skeleton
            minW={'200px'}
            minH={'200px'}
            zIndex={1}
            isLoaded={isGetCampaignByIdSuccess}
            mt={'-40px'}
          >
            <Image
              bgColor={'#ffffff'}
              src={campaign?.token_info.file.uri}
              minW={'200px'}
              minH={'200px'}
              maxW={'200px'}
              maxH={'200px'}
              objectFit={'scale-down'}
              border={'1px solid'}
              borderRadius={'10px'}
              borderColor={'coap.grey.1'}
            />
          </Skeleton>
        </Center>
        <Center>
          <Box
            w={'100%'}
            mt={'-80px'}
            mb={'10px'}
            p={'100px 20px 20px 20px'}
            bgColor={'coap.blue.3'}
            color={'#ffffff'}
            borderRadius={'10px'}
          >
            <HStack w={'100%'} mb={'10px'}>
              <Box></Box>
              <Spacer />
              <Box></Box>
            </HStack>
            <Skeleton isLoaded={isGetCampaignByIdSuccess}>
              <InfoBox
                label={'Campaign Name'}
                value={campaign?.campaign_info.title}
              />
            </Skeleton>
            <Skeleton isLoaded={isGetCampaignByIdSuccess}>
              <InfoBox
                label={'COAP NFT Name'}
                value={campaign?.token_info.token_name}
              />
            </Skeleton>
            <Skeleton isLoaded={isGetCampaignByIdSuccess}>
              <InfoBox
                label={'COAP NFT Name'}
                value={<Box textTransform={'capitalize'}>{campaign?.type}</Box>}
              />
            </Skeleton>
          </Box>
        </Center>
        <Spacer />
        <Center mt={'auto'}>
          <VStack w={'full'} gap={'15px'}>
            <Button
              w={'full'}
              borderRadius={'50px'}
              bgColor={'coap.yellow.2'}
              _active={{
                bgColor: 'coap.yellow.2',
              }}
              _hover={{
                bgColor: 'coap.yellow.2',
              }}
              onClick={onOpen}
              isLoading={isGetCampaignByIdPending}
            >
              Scan QR Code
            </Button>
          </VStack>
        </Center>
      </Box>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'7xl'}>
        <ModalOverlay />
        <ModalContent>
          <VStack p={'10px'}>
            <Box pos={'relative'} textAlign={'center'} mb={'20px'} w={'full'}>
              <Button
                pos={'absolute'}
                left={0}
                h={'24px'}
                variant={'link'}
                color={'coap.blue.5'}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Box fontSize={'md'} fontWeight={'semibold'}>
                Scan COAP QR Code
              </Box>
            </Box>
            <Box
              fontSize={'sm'}
              px={'25px'}
              textAlign={'center'}
              opacity={'0.8'}
            >
              Open MyCOAP on customer's device, select NFT and display QR code
              for scanning.
            </Box>
            <Center
              flex={1}
              alignItems={'center'}
              w={'100%'}
              h={'100%'}
              maxW={'1024px'}
              borderRadius={'5px'}
            >
              <AspectRatio ratio={1 / 1} maxW={'768px'} w={'100%'}>
                <QRCodeScanner
                  onScan={(result) => handleScanAndRedirect(result?.getText())}
                  onError={(error) => {
                    onClose();
                    onAlertOpen();
                  }}
                />
              </AspectRatio>
            </Center>
          </VStack>
        </ModalContent>
      </Modal>
      <AlertDialog
        isCentered
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader textAlign={'center'}>
              Something went wrong when acccessing your device's camera
            </AlertDialogHeader>
            <AlertDialogBody>
              Maybe your device does not have a camera or you have not granted
              us permission to access your camera. Please check the permission
              and refresh this website.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
}

function InfoBox({
  label,
  value,
}: {
  label?: string;
  value?: string | React.ReactNode;
}) {
  return (
    <>
      <Text textAlign={'center'} mb={'5px'}>
        {label}
      </Text>
      <Box
        w={'full'}
        bgColor={'coap.blue.4'}
        textAlign={'center'}
        borderRadius={'10px'}
        p={'10px 0px'}
        mb={'10px'}
      >
        <Box fontWeight={'semibold'}>{value}</Box>
      </Box>
    </>
  );
}

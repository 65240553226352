import scanService from './services/scan';

export const signIn = async ({
  username,
  password,
  account_nbr,
}: Param.SignIn): Promise<Entity.SignIn> => {
  const { data } = await scanService.post<Entity.SignIn>('/v1/signin', {
    username,
    password,
    account_nbr,
  });
  return data;
};

import { useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Container,
  Box,
  Text,
  Heading,
  Center,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  Link,
  Spacer,
  Show,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { AsyncState } from '../types/state.enum';
import useAppDispatch from '../redux/hooks/useAppDispatch';
import * as accountThunk from '../redux/thunks/account';
import CoapWhiteLogo from '../images/coap_logo_white.png';
import Banner1440px from '../images/banner_1440px.jpg';
import Banner1024px from '../images/banner_1024px.jpg';
import Banner768px from '../images/banner_768px.jpg';
import useAppSelector from '../redux/hooks/useAppSelector';

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accountNbr = searchParams.get('account_nbr');
  const isGetMeSuccess = useAppSelector(
    (state) => state.account.getMeStatus === AsyncState.SUCCESS
  );
  const isGetMePending = useAppSelector(
    (state) => state.account.getMeStatus === AsyncState.PENDING
  );
  const [isLargerThan1024px] = useMediaQuery('(min-width: 1024px)');
  const [isLargerThan768px] = useMediaQuery('(min-width: 768px)');

  const _bannerImg = useMemo(() => {
    if (isLargerThan1024px) return Banner1440px;
    if (isLargerThan768px) return Banner1024px;

    return Banner768px;
  }, [isLargerThan1024px, isLargerThan768px]);

  const formik = useFormik({
    initialValues: {
      account_nbr: accountNbr || '',
      username: '',
      password: '',
      errorMsg: '',
    },
    onSubmit: async ({ account_nbr, username, password }) => {
      try {
        await dispatch(
          accountThunk.signIn({ account_nbr, username, password })
        ).unwrap();
        navigate('/campaign', { replace: true });
      } catch (e: any) {
        if (e.code === 40001 || e.code === 40000) {
          formik.setFieldError('errorMsg', 'Unauthorised');
          return;
        }
      }
    },
  });

  useEffect(() => {
    if (!isGetMeSuccess) {
      dispatch(accountThunk.getMe());
      dispatch(accountThunk.getIssuerInfo());
    }
  }, [dispatch, isGetMeSuccess]);

  if (isGetMePending) {
    return null;
  }

  if (isGetMeSuccess) {
    return <Navigate to={'/campaign'} replace />;
  }

  return (
    <Container
      display={'flex'}
      flexDir={'column'}
      maxW={'1440px'}
      h={'100%'}
      w={'100%'}
      p={0}
    >
      <Show above={'md'}>
        <Box
          minH={'450px'}
          bgColor={'coap.blue.5'}
          bgRepeat={'no-repeat'}
          bgPos={'center'}
          p={'25px'}
          color={'#ffffff'}
        >
          <Image src={CoapWhiteLogo} w={'150px'} mb={'50px'} />
          <Heading fontWeight={'semibold'}>Secure, Dynamic QR Code</Heading>
          <Heading fontWeight={'semibold'} mb={'50px'}>
            Solution for NFT Interaction
          </Heading>
          <Text fontWeight={'light'} fontSize={'xl'}>
            Designed to revolutionize the way businesses
          </Text>
          <Text fontWeight={'light'} fontSize={'xl'}>
            Taking customer engagement to new heights
          </Text>
        </Box>
      </Show>
      <Box display={'flex'} flexDir={'column'} flex={1} w={'100%'}>
        <Center>
          <Box
            as={'form'}
            onSubmit={formik.handleSubmit}
            w={'100%'}
            maxW={'425px'}
            h={'100%'}
            p={'15px'}
          >
            <Heading
              textAlign={'center'}
              my={'40px'}
              opacity={'0.6'}
              fontWeight={'normal'}
              letterSpacing={'2px'}
            >
              COAP Scanner Login
            </Heading>
            <FormControl>
              <Input
                mb={'20px'}
                borderColor={'coap.blue.5'}
                placeholder={'Account ID'}
                name={'account_nbr'}
                type={'text'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_nbr}
                disabled={formik.isSubmitting}
              />
            </FormControl>
            <FormControl isInvalid={!!formik.errors.username}>
              <Input
                mb={'20px'}
                borderColor={'coap.blue.5'}
                placeholder={'Username'}
                name={'username'}
                type={'text'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                disabled={formik.isSubmitting}
              />
              <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.password}>
              <Input
                mb={'40px'}
                borderColor={'coap.blue.5'}
                placeholder={'Password'}
                name={'password'}
                type={'password'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                disabled={formik.isSubmitting}
              />
            </FormControl>
            <Button
              mb={'20px'}
              type={'submit'}
              isLoading={formik.isSubmitting}
              w={'full'}
              color={'#ffffff'}
              bgColor={'coap.blue.5'}
              _active={{
                bgColor: 'coap.blue.5',
              }}
              _hover={{
                bgColor: 'coap.blue.5',
              }}
            >
              Login
            </Button>
            <Text
              color={'red'}
              textAlign={'center'}
              fontSize={'md'}
              fontWeight={'light'}
              mb={'20px'}
              visibility={!!formik.errors.errorMsg ? 'visible' : 'hidden'}
            >
              {formik.errors.errorMsg}
            </Text>
            <Text
              textAlign={'center'}
              fontSize={'md'}
              fontWeight={'light'}
              mb={'10px'}
            >
              Log in to access your COAP Scanner for Web3 interactions,
              including Exclusive Memberships, Special Vouchers, and more.
            </Text>
            <Text textAlign={'center'} fontSize={'sm'} fontWeight={'light'}>
              By proceeding, you agree to our{' '}
              <Link
                isExternal
                href={
                  'https://s3.ap-southeast-1.amazonaws.com/static.coap.app/terms_of_use.html'
                }
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                isExternal
                href={
                  'https://s3.ap-southeast-1.amazonaws.com/static.coap.app/privacy_policy.html'
                }
              >
                Privacy Policy
              </Link>
            </Text>
          </Box>
        </Center>
        <Spacer />
        <Center w={'100%'}>
          <Text
            p={'20px'}
            textAlign={'center'}
            maxW={'768px'}
            fontSize={'xs'}
            fontWeight={'light'}
          >
            &#9400;{new Date().getFullYear()} All Rights Reserved. COAP
            <sup>&#169;</sup> is a registered trademark of Aurum Sky Technology
            LTD.{' '}
            <Link
              isExternal
              href={
                'https://s3.ap-southeast-1.amazonaws.com/static.coap.app/terms_of_use.html'
              }
            >
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              isExternal
              href={
                'https://s3.ap-southeast-1.amazonaws.com/static.coap.app/privacy_policy.html'
              }
            >
              Privacy Policy
            </Link>
          </Text>
        </Center>
      </Box>
    </Container>
  );
}

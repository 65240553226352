import { useCallback, useEffect } from 'react';
import {
  Container,
  Box,
  Center,
  Image,
  Text,
  Button,
  VStack,
} from '@chakra-ui/react';
import { Navigate, useNavigate } from 'react-router-dom';
import useAppSelector from '../redux/hooks/useAppSelector';
import useAppDispatch from '../redux/hooks/useAppDispatch';
import * as redemptionThunk from '../redux/thunks/redemption';
import * as redemptionAction from '../redux/actions/redemption';
import { AsyncState } from '../types/state.enum';

export default function RedeemConfirm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fetchInfoResult = useAppSelector(
    (state) => state.redemption.fetchInfoResult
  );
  const isRedeemStatusPending = useAppSelector(
    (state) => state.redemption.redeemStatus === AsyncState.PENDING
  );

  const handleRedeem = useCallback(async () => {
    try {
      await dispatch(
        redemptionThunk.redeem({
          campaign_id: fetchInfoResult?.campaign.id as string,
          token: fetchInfoResult?.token as string,
        })
      ).unwrap();

      navigate('/redeem/success', { replace: true });
    } catch (e) {
      navigate(`/redeem/fail?r=${window.btoa(JSON.stringify(e))}`);
    }
  }, [
    dispatch,
    fetchInfoResult?.campaign.id,
    fetchInfoResult?.token,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(redemptionAction.resetFetchInfo());
    };
  }, [dispatch]);

  if (!fetchInfoResult) {
    return <Navigate to={'/campaign'} replace />;
  }

  return (
    <Container maxW={'2560px'} p={0} pb={'20px'}>
      <Box p={'50px 10px 10px 10px'}>
        <Center>
          <Image
            bgColor={'#ffffff'}
            src={fetchInfoResult.campaign.token_uri}
            minW={'200px'}
            minH={'200px'}
            maxW={'200px'}
            maxH={'200px'}
            objectFit={'scale-down'}
            border={'1px solid'}
            borderRadius={'10px'}
            borderColor={'coap.grey.1'}
            zIndex={1}
          />
        </Center>
        <Center>
          <Box
            w={'100%'}
            mt={'-80px'}
            mb={'10px'}
            p={'100px 20px 20px 20px'}
            bgColor={'coap.grey.1'}
            borderRadius={'10px'}
          >
            <Text textAlign={'center'} mb={'5px'}>
              Campaign Name
            </Text>
            <Box
              w={'full'}
              bgColor={'coap.blue.5'}
              textAlign={'center'}
              borderRadius={'10px'}
              p={'10px 0px'}
              mb={'10px'}
            >
              <Box fontWeight={'semibold'} color={'#ffffff'}>
                {fetchInfoResult.campaign.campaign_info.title}
              </Box>
            </Box>
            <Text textAlign={'center'} mb={'5px'}>
              COAP NFT Name
            </Text>
            <Box
              w={'full'}
              bgColor={'coap.blue.5'}
              textAlign={'center'}
              borderRadius={'10px'}
              p={'10px 0px'}
              mb={'10px'}
            >
              <Box fontWeight={'semibold'} color={'#ffffff'}>
                {fetchInfoResult.campaign.token_info.token_name}
              </Box>
            </Box>
            <Text textAlign={'center'} mb={'5px'}>
              User Email
            </Text>
            <Box
              w={'full'}
              bgColor={'coap.blue.5'}
              textAlign={'center'}
              borderRadius={'10px'}
              p={'10px 0px'}
              mb={'10px'}
            >
              <Box fontWeight={'semibold'} color={'#ffffff'}>
                {fetchInfoResult.user.email}
              </Box>
            </Box>
            <Text textAlign={'center'} mb={'5px'}>
              Use Times
            </Text>
            <Box
              w={'full'}
              bgColor={'coap.blue.5'}
              textAlign={'center'}
              borderRadius={'10px'}
              p={'10px 0px'}
              mb={'10px'}
            >
              <Box fontWeight={'semibold'} color={'#ffffff'}>
                {fetchInfoResult.current_usage}
              </Box>
            </Box>
          </Box>
        </Center>
        <Center>
          <VStack w={'full'} gap={'8px'}>
            <Button
              w={'full'}
              borderRadius={'50px'}
              bgColor={'coap.yellow.2'}
              _active={{
                bgColor: 'coap.yellow.2',
              }}
              _hover={{
                bgColor: 'coap.yellow.2',
              }}
              isLoading={isRedeemStatusPending}
              onClick={handleRedeem}
            >
              Accept
            </Button>
            <Button
              w={'full'}
              borderRadius={'50px'}
              bgColor={'coap.grey.1'}
              _active={{
                bgColor: 'coap.grey.1',
              }}
              _hover={{
                bgColor: 'coap.grey.1',
              }}
              onClick={() => navigate('/campaigns', { replace: true })}
            >
              Cancel
            </Button>
          </VStack>
        </Center>
      </Box>
    </Container>
  );
}

import createAppAsyncThunk from '../helpers/createAppAsyncThunk';
import * as authApi from '../../apis/auth';
import * as accountApi from '../../apis/account';

export const signIn = createAppAsyncThunk(
  'account/signIn',
  async ({ account_nbr, username, password }: Param.SignIn, store) => {
    try {
      const { token } = await authApi.signIn({
        account_nbr,
        username,
        password,
      });

      window.localStorage.setItem('coap_scan_access_token', token);
    } catch (e: any) {
      return store.rejectWithValue({
        msg: e.response.data.msg,
        code: e.response.data.code,
      });
    }
  }
);

export const signOut = createAppAsyncThunk('account/signOut', () => {
  window.localStorage.removeItem('coap_scan_access_token');
  window.location.assign('/login');
});

export const getMe = createAppAsyncThunk('account/getMe', async () => {
  return await accountApi.getMe();
});

export const getIssuerInfo = createAppAsyncThunk(
  'account/getAccountInfo',
  async () => {
    return await accountApi.getIssuerInfo();
  }
);

import { useSearchParams, Navigate, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Center,
  Text,
  Button,
  Image,
  VStack,
} from '@chakra-ui/react';
import useAppSelector from '../redux/hooks/useAppSelector';
import ErrorImg from '../images/error.jpg';

export default function RedeemFail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const r = searchParams.get('r') || '';
  const reason: Record<string, string> = JSON.parse(window.atob(r));
  const campaignId = useAppSelector((state) => state.campaign.campaign?.id);

  if (!r || !campaignId) {
    return <Navigate to={'/campaign'} replace />;
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <VStack
        p={'50px 10px 10px 10px'}
        w={'full'}
        h={'calc(100dvh - 50px)'}
        pb={'50px'}
      >
        <Box flex={1}>
          <Center mb={'20px'}>
            <Image w={'200px'} src={ErrorImg} />
          </Center>
          <Text
            fontSize={'lg'}
            fontWeight={'semibold'}
            textTransform={'capitalize'}
            textAlign={'center'}
            mb={'20px'}
          >
            {reason.msg}
          </Text>
        </Box>
        <Button
          w={'full'}
          borderRadius={'50px'}
          bgColor={'coap.yellow.2'}
          _active={{
            bgColor: 'coap.yellow.2',
          }}
          _hover={{
            bgColor: 'coap.yellow.2',
          }}
          onClick={() => navigate(`/campaign/${campaignId}?scan=true`)}
        >
          Scan Again
        </Button>
      </VStack>
    </Container>
  );
}

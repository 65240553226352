import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../store';

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: any;
}>();

export default createAppAsyncThunk;

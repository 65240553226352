import React from 'react';
import ReactDOM from 'react-dom/client';
import eruda from 'eruda';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';

if (config.env === 'production') {
  console.log = () => {};
} else {
  eruda.init();
}

console.log('Environment: ', config.nodeEnv);
console.log('Build: ', config.build);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

reportWebVitals();

import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { store } from './redux/store';
import theme from './theme';
import Router from './Router';

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </Provider>
  );
}

export default App;

import { useEffect, useRef, useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { BrowserQRCodeReader, type IScannerControls } from '@zxing/browser';
import type { Result } from '@zxing/library';

interface Props {
  onScan: (result: Result | undefined) => void;
  onError: (error: string) => void;
}

export default function QRCodeScanner(props: Props) {
  const { onScan, onError } = props;
  const previewRef = useRef<HTMLVideoElement>(null);
  const scannerControl = useRef<IScannerControls>();

  const initScanner = useCallback(async () => {
    try {
      console.log('QRCodeScanner initScanner');
      const codeReader = new BrowserQRCodeReader();

      const controls = await codeReader.decodeFromConstraints(
        {
          video: { advanced: [{ facingMode: { exact: 'environment' } }] },
          audio: false,
        },
        previewRef.current as HTMLVideoElement,
        (result) => {
          console.log('QRCodeScanner onScan: ', result);
          onScan(result);
        }
      );

      scannerControl.current = controls;
    } catch (e) {
      console.error(e);
      if (e instanceof DOMException) {
        onError(e.message);
        return;
      }

      onError('Unknown error');
    }
  }, [onError, onScan]);

  const stopScanner = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    stream.getVideoTracks().forEach((track) => track.stop());
    scannerControl.current?.stop();
  }, []);

  useEffect(() => {
    console.log('QRCodeScanner mounts');
    initScanner();

    return () => {
      console.log('QRCodeScanner unmounts');
      stopScanner();
    };
  }, [initScanner, stopScanner]);

  return (
    <Box
      as={'video'}
      ref={previewRef}
      h={'100%'}
      w={'100%'}
      objectFit={'cover'}
    />
  );
}

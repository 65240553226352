import { createReducer } from '@reduxjs/toolkit';
import * as accountThunk from '../thunks/account';
import { AsyncState } from '../../types/state.enum';

interface AccountReducerState {
  signInStatus: AsyncState;
  getMeStatus: AsyncState;
  me: Entity.Me | null;
  issuerInfo: Entity.IssuerInfo | null;
  getIssuerInfoStatus: AsyncState;
}

const initialState: AccountReducerState = {
  signInStatus: AsyncState.IDLE,
  getMeStatus: AsyncState.IDLE,
  me: null,
  getIssuerInfoStatus: AsyncState.IDLE,
  issuerInfo: null,
};

const accountReducer = createReducer(initialState, (builder) => {
  builder.addCase(accountThunk.signIn.pending, (state) => {
    state.signInStatus = AsyncState.PENDING;
  });

  builder.addCase(accountThunk.signIn.fulfilled, (state) => {
    state.signInStatus = AsyncState.SUCCESS;
  });

  builder.addCase(accountThunk.signIn.rejected, (state) => {
    state.signInStatus = AsyncState.REJECTED;
  });

  builder.addCase(accountThunk.getMe.pending, (state) => {
    state.getMeStatus = AsyncState.PENDING;
  });

  builder.addCase(accountThunk.getMe.fulfilled, (state, { payload }) => {
    state.me = payload;
    state.getMeStatus = AsyncState.SUCCESS;
  });

  builder.addCase(accountThunk.getMe.rejected, (state) => {
    state.getMeStatus = AsyncState.REJECTED;
  });

  builder.addCase(accountThunk.getIssuerInfo.pending, (state) => {
    state.getIssuerInfoStatus = AsyncState.PENDING;
  });

  builder.addCase(
    accountThunk.getIssuerInfo.fulfilled,
    (state, { payload }) => {
      state.getIssuerInfoStatus = AsyncState.SUCCESS;
      state.issuerInfo = payload;
    }
  );

  builder.addCase(accountThunk.getIssuerInfo.rejected, (state) => {
    state.getIssuerInfoStatus = AsyncState.REJECTED;
  });
});

export default accountReducer;

import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import InApp from './layouts/InApp';
import Login from './pages/Login';
import Campaign from './pages/Campaign';
import CampaignById from './pages/CampaignById';
import RedeemConfirm from './pages/RedeemConfirm';
import RedeemSuccess from './pages/RedeemSuccess';
import RedeemFail from './pages/RedeemFail';
import RedeemHistory from './pages/RedeemHistory';
import User from './pages/User';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path={'/login'} />
        <Route element={<InApp />} path={'/'}>
          <Route element={<Navigate to={'/campaign'} replace />} index />
          <Route element={<User />} path={'/user'} />
          <Route element={<Campaign />} path={'/campaign'} />
          <Route element={<CampaignById />} path={'/campaign/:id'} />
          <Route element={<RedeemConfirm />} path={'/redeem/confirm'} />
          <Route element={<RedeemSuccess />} path={'/redeem/success'} />
          <Route element={<RedeemFail />} path={'/redeem/fail'} />
          <Route element={<RedeemHistory />} path={'/redeem/history'} />
        </Route>
        <Route element={<Navigate to={'/campaign'} replace />} path={'*'} />
      </Routes>
    </BrowserRouter>
  );
}

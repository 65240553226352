import axios, { AxiosError } from 'axios';
import config from '../../config';

const scanService = axios.create({
  baseURL: config.serverBaseUrl.scan,
});

scanService.interceptors.request.use((config) => {
  config.headers['X-Access-Token'] = window.localStorage.getItem(
    'coap_scan_access_token'
  );

  return config;
});

scanService.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (
      error instanceof AxiosError &&
      error.response?.data.code === 40001 &&
      !['/v1/account_info', '/v1/me'].includes(error.config?.url || '')
    ) {
      window.localStorage.removeItem('coap_scan_access_token');
      window.location.assign('/login');
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default scanService;

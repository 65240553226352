import createAppAsyncThunk from '../helpers/createAppAsyncThunk';
import * as redemptionApi from '../../apis/redemption';

export const fetchInfo = createAppAsyncThunk(
  'redemption/fetchInfo',
  async (params: Param.RedemptionFetchInfo, store) => {
    try {
      const response = await redemptionApi.fetchInfo(params);
      return {
        ...response.data,
        token: params.token,
      };
    } catch (e: any) {
      return store.rejectWithValue({
        msg: e.response.data.msg,
        code: e.response.data.code,
      });
    }
  }
);

export const redeem = createAppAsyncThunk(
  'redemption/redeem',
  async (params: Param.RedemptionRedeem) => {
    return await redemptionApi.redeem(params);
  }
);

export const getRedemptions = createAppAsyncThunk(
  'redemption/getRedemptions',
  async (params: Param.RedemptionPagination, store) => {
    try {
      const response = await redemptionApi.getRedemptions(params);

      const state = store.getState();
      const hasMore = state.redemption.redemptionList.has_more;
      const data = state.redemption.redemptionList.data;

      if (hasMore && data.length > 0 && params.page > 1) {
        response.data = [...data, ...response.data];
      }

      return response;
    } catch (e: any) {
      return store.rejectWithValue({
        msg: e.response.data.msg,
        code: e.response.data.code,
      });
    }
  }
);

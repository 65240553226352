import { useEffect, useCallback } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import {
  Container,
  HStack,
  Image,
  Spacer,
  IconButton,
  Box,
  Text,
  Link,
  Avatar,
  Spinner,
  Center,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import config from '../config';
import { AsyncState } from '../types/state.enum';
import useAppDispatch from '../redux/hooks/useAppDispatch';
import useAppSelector from '../redux/hooks/useAppSelector';
import * as accountThunk from '../redux/thunks/account';
import CoapWhiteLogo from '../images/coap_logo_white.png';

export default function InApp() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isGetMePending = useAppSelector(
    (state) => state.account.getMeStatus === AsyncState.PENDING
  );
  const isGetMeRejected = useAppSelector(
    (state) => state.account.getMeStatus === AsyncState.REJECTED
  );
  const isGetMeSuccess = useAppSelector(
    (state) => state.account.getMeStatus === AsyncState.SUCCESS
  );
  const issuerInfo = useAppSelector((state) => state.account.issuerInfo);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelectNavItem = useCallback(
    (path: string) => {
      navigate(path);
      onClose();
    },
    [navigate, onClose]
  );

  useEffect(() => {
    if (!isGetMeSuccess) {
      dispatch(accountThunk.getMe());
      dispatch(accountThunk.getIssuerInfo());
    }
  }, [dispatch, isGetMeSuccess]);

  if (isGetMeRejected) {
    return <Navigate to={'/login'} replace />;
  }

  return (
    <Container maxW={'2560px'} p={0}>
      <HStack
        w={'100%'}
        h={'50px'}
        position={'fixed'}
        top={0}
        bgColor={'coap.blue.3'}
        p={'0px 20px'}
        zIndex={2}
      >
        <Image src={CoapWhiteLogo} w={'90px'} />
        <Spacer />
        <IconButton
          size={'sm'}
          border={'0.1px solid #ffffff'}
          bg={'transparent'}
          aria-label={'side menu'}
          icon={<HamburgerIcon color={'#ffffff'} fontSize={'xl'} />}
          _active={{
            bg: 'transparent',
          }}
          _hover={{
            bg: 'transparent',
          }}
          onClick={isOpen ? onClose : onOpen}
        />
      </HStack>
      <Drawer placement={'right'} isOpen={isOpen} onClose={onClose} closeOnEsc>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader pt={'50px'}>
            <HStack alignItems={'start'} gap={'15px'}>
              <Avatar
                border={'0.1px solid lightgrey'}
                size={'lg'}
                name={issuerInfo?.name}
                src={issuerInfo?.logo_uri}
                bgColor={'#ffffff'}
                icon={<Spinner color={'coap.blue.5'} />}
              />
              <Box>
                <Text
                  hidden={!issuerInfo?.name}
                  fontSize={'md'}
                  fontWeight={'normal'}
                >
                  {issuerInfo?.name}
                </Text>
                <Link
                  hidden={!issuerInfo?.website}
                  fontSize={'sm'}
                  fontWeight={'light'}
                  isExternal
                  href={issuerInfo?.website}
                >
                  {issuerInfo?.website} <ExternalLinkIcon mx="2px" />
                </Link>
              </Box>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <Box
              display={'flex'}
              alignItems={'center'}
              w={'full'}
              h={'40px'}
              m={'5px 0px 5px 0px'}
              onClick={() => handleSelectNavItem('/user')}
            >
              User
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              w={'full'}
              h={'40px'}
              m={'5px 0px 5px 0px'}
              onClick={() => handleSelectNavItem('/campaign')}
            >
              Campaign
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              w={'full'}
              h={'40px'}
              m={'5px 0px 5px 0px'}
              onClick={() => handleSelectNavItem('/redeem/history')}
            >
              Redemption History
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              w={'full'}
              h={'40px'}
              m={'5px 0px 5px 0px'}
              onClick={() => dispatch(accountThunk.signOut())}
            >
              Sign Out
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Text fontWeight={'light'} fontSize={'xs'}>
              {config.nodeEnv}:{config.build}
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      {isGetMePending ? (
        <Center h={'100vh'} w={'100vw'}>
          <Spinner color={'coap.blue.5'} size={'xl'} />
        </Center>
      ) : (
        <Container maxW={'2560px'} p={'35px 0px 0px 0px'}>
          <Outlet />
        </Container>
      )}
    </Container>
  );
}

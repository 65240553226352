import { createReducer } from '@reduxjs/toolkit';
import * as campaignThunk from '../thunks/campaign';
import { AsyncState } from '../../types/state.enum';

interface CampaignReducerState {
  getCampaignStatus: AsyncState;
  campaignList: Entity.CampaignList;
  getCampaignByIdStatus: AsyncState;
  campaign: Entity.Campaign | null;
}

const initialState: CampaignReducerState = {
  getCampaignStatus: AsyncState.PENDING,
  campaignList: {
    has_more: false,
    total: 0,
    total_count: 0,
    total_page: 0,
    data: [],
  },
  getCampaignByIdStatus: AsyncState.PENDING,
  campaign: null,
};

const campaignReducer = createReducer(initialState, (builder) => {
  builder.addCase(campaignThunk.getCampaign.pending, (state) => {
    state.getCampaignStatus = AsyncState.PENDING;
  });

  builder.addCase(campaignThunk.getCampaign.fulfilled, (state, { payload }) => {
    state.getCampaignStatus = AsyncState.SUCCESS;
    state.campaignList = payload;
  });

  builder.addCase(campaignThunk.getCampaign.rejected, (state) => {
    state.getCampaignStatus = AsyncState.REJECTED;
  });

  builder.addCase(campaignThunk.getCampaignById.pending, (state) => {
    state.getCampaignByIdStatus = AsyncState.PENDING;
  });

  builder.addCase(
    campaignThunk.getCampaignById.fulfilled,
    (state, { payload }) => {
      state.campaign = payload;
      state.getCampaignByIdStatus = AsyncState.SUCCESS;
    }
  );

  builder.addCase(campaignThunk.getCampaignById.rejected, (state) => {
    state.getCampaignByIdStatus = AsyncState.REJECTED;
  });
});

export default campaignReducer;

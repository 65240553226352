import { Container, Avatar, Box, Center, Text, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import useAppSelector from '../redux/hooks/useAppSelector';

export default function User() {
  const issuerInfo = useAppSelector((state) => state.account.issuerInfo);
  const me = useAppSelector((state) => state.account.me);

  return (
    <Container maxW={'2560px'} pt={'50px'} fontSize={'sm'}>
      <Center mb={'20px'}>
        <Avatar size={'xl'} src={issuerInfo?.logo_uri} />
      </Center>
      <Box mb={'10px'}>
        <Text>Username</Text>
        <Text fontWeight={'light'}>{me?.username}</Text>
      </Box>
      <Box mb={'10px'}>
        <Text>Issuer Name</Text>
        <Text fontWeight={'light'}>{issuerInfo?.name}</Text>
      </Box>
      <Box mb={'10px'}>
        <Text>Issuer Website</Text>
        <Link fontWeight={'light'} href={issuerInfo?.website} isExternal>
          {issuerInfo?.website} <ExternalLinkIcon mx="2px" />
        </Link>
      </Box>
    </Container>
  );
}

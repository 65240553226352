import createAppAsyncThunk from '../helpers/createAppAsyncThunk';
import * as campaignApi from '../../apis/campaign';

export const getCampaign = createAppAsyncThunk(
  'campaign/getCampaign',
  async (params: Param.CampaignPagination, store) => {
    try {
      const response = await campaignApi.getCampaign(params);

      const state = store.getState();
      const hasMore = state.campaign.campaignList.has_more;
      const list = state.campaign.campaignList.data;

      if (hasMore && list.length > 0 && params.page > 1) {
        response.data = [...list, ...response.data];
      }

      return response;
    } catch (e: any) {
      return store.rejectWithValue({
        msg: e.response.data.msg,
        code: e.response.data.code,
      });
    }
  }
);

export const getCampaignById = createAppAsyncThunk(
  'campaign/getCampaignById',
  async (id: string, store) => {
    try {
      return await campaignApi.getCampaignById(id);
    } catch (e: any) {
      return store.rejectWithValue({
        msg: e.response.data.msg,
        code: e.response.data.code,
      });
    }
  }
);

import { createReducer } from '@reduxjs/toolkit';
import * as redemptionThunk from '../thunks/redemption';
import * as redemptionAction from '../actions/redemption';
import { AsyncState } from '../../types/state.enum';

interface RedemptionReducerState {
  fetchInfoStatus: AsyncState;
  fetchInfoResult: Entity.RedemptionFetchInfo | null;
  redeemStatus: AsyncState;
  redeemResult: Entity.RedemptionHistory | null;
  redemptionList: Entity.RedemptionList;
  getRedemptionsStatus: AsyncState;
}

const initialState: RedemptionReducerState = {
  fetchInfoStatus: AsyncState.IDLE,
  fetchInfoResult: null,
  redeemStatus: AsyncState.IDLE,
  redeemResult: null,
  redemptionList: {
    has_more: false,
    total: 0,
    total_count: 0,
    total_page: 0,
    data: [],
  },
  getRedemptionsStatus: AsyncState.IDLE,
};

const redemptionReducer = createReducer(initialState, (builder) => {
  builder.addCase(redemptionThunk.fetchInfo.pending, (state) => {
    state.fetchInfoStatus = AsyncState.PENDING;
  });

  builder.addCase(redemptionThunk.fetchInfo.fulfilled, (state, { payload }) => {
    state.fetchInfoResult = payload;
    state.fetchInfoStatus = AsyncState.SUCCESS;
  });

  builder.addCase(redemptionThunk.fetchInfo.rejected, (state) => {
    state.fetchInfoStatus = AsyncState.REJECTED;
  });

  builder.addCase(redemptionThunk.redeem.pending, (state) => {
    state.redeemStatus = AsyncState.PENDING;
  });

  builder.addCase(redemptionThunk.redeem.fulfilled, (state, { payload }) => {
    state.redeemResult = payload;
    state.redeemStatus = AsyncState.SUCCESS;
  });

  builder.addCase(redemptionThunk.redeem.rejected, (state) => {
    state.redeemStatus = AsyncState.REJECTED;
  });

  builder.addCase(redemptionAction.resetFetchInfo, (state) => {
    state.fetchInfoStatus = AsyncState.IDLE;
    state.fetchInfoResult = null;
  });

  builder.addCase(redemptionAction.resetRedeemResult, (state) => {
    state.redeemStatus = AsyncState.IDLE;
    state.redeemResult = null;
  });

  builder.addCase(redemptionThunk.getRedemptions.pending, (state) => {
    state.getRedemptionsStatus = AsyncState.PENDING;
  });

  builder.addCase(
    redemptionThunk.getRedemptions.fulfilled,
    (state, { payload }) => {
      state.redemptionList = payload;
      state.getRedemptionsStatus = AsyncState.SUCCESS;
    }
  );

  builder.addCase(redemptionThunk.getRedemptions.rejected, (state) => {
    state.getRedemptionsStatus = AsyncState.REJECTED;
  });
});

export default redemptionReducer;

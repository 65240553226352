import scanService from './services/scan';

export const getMe = async (): Promise<Entity.Me> => {
  const { data } = await scanService.get<Entity.Me>('/v1/me');

  return data;
};

export const getIssuerInfo = async (): Promise<Entity.IssuerInfo> => {
  const { data } = await scanService.get<Entity.IssuerInfo>('/v1/account_info');

  return data;
};

import { AxiosResponse } from 'axios';
import scanService from './services/scan';

export const fetchInfo = (
  params: Param.RedemptionFetchInfo
): Promise<
  AxiosResponse<Entity.RedemptionFetchInfo, Entity.RedemptionFetchInfo>
> => {
  return scanService.post<Entity.RedemptionFetchInfo>(
    '/v1/redemptions/_fetch_info',
    params
  );
};

export const redeem = async (
  params: Param.RedemptionRedeem
): Promise<Entity.RedemptionHistory> => {
  const { data } = await scanService.post<Entity.RedemptionHistory>(
    '/v1/redemptions/_redeem',
    params
  );

  return data;
};

export const getRedemptions = async (
  params: Param.CampaignPagination
): Promise<Entity.RedemptionList> => {
  const { data } = await scanService.get<Entity.RedemptionList>(
    '/v1/redemptions',
    {
      params,
    }
  );

  return data;
};

export enum CampaignType {
  UNKNOWN = 'unknown',
  VOUCHER = 'voucher',
  MEMBERSHIP = 'membership',
  BADGE = 'badge',
  ATTENDANCE = 'attendance',
  SKILL = 'skill',
  ACADEMIC = 'academic',
}

export enum CampaignStatus {
  PENDING = 'pending',
  PROCEEDING = 'proceeding',
  FINISHED = 'finished',
  FAILED = 'failed',
  PUBLISHED = 'published',
}

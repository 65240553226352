import { env } from '../env';
const config = {
  nodeEnv: env.NODE_ENV || 'development',
  env: env.REACT_APP_ENV || 'development',
  build: env.REACT_APP_BUILD_VERSION || 'local',
  serverBaseUrl: {
    scan: env.REACT_APP_SCAN_SERVER_BASE_URL,
  },
};

export default config;

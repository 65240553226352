import { Waypoint as ReactWaypoint } from 'react-waypoint';

interface Props {
  onEnter?: () => void;
  onLeave?: () => void;
  disabled?: boolean;
}

export default function Waypoint(props: Props) {
  const { onEnter, onLeave, disabled } = props;
  return disabled ? null : (
    <ReactWaypoint onEnter={onEnter} onLeave={onLeave} />
  );
}
